import React from 'react'
import { IntlProvider } from 'react-intl'

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapPageElement = ({ element, props }) => {
  const defaultLanguage = props.pageContext.defaultLanguage
  const language = props.pageContext.language
  const translations = props.pageContext.translations
  return (
    <IntlProvider defaultLocale={defaultLanguage} locale={language} messages={translations}>
      {element}
    </IntlProvider>
  )
}

export const onRenderBody = ({ setHeadComponents }) => {
  setHeadComponents([
    <link
      key="Montserrat"
      rel="preload"
      href="/fonts/Montserrat_wght.ttf"
      as="font"
      type="font/ttf"
      crossOrigin="anonymous"
    />,
  ])
}
